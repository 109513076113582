import { styled } from "styled-components";

const Wrapper = styled.section`
  font-family: "Poppins", sans-serif;
  position: absolute;
  right: 3rem;
  top: -4rem;

  .search-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sub-container {
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    border-radius: 4px;
    padding: 8px;
  }

  .search-input {
    border: none;
    background: none;
    outline: none;
    padding-left: 10px;
    font-size: 16px;
    width: 200px;
    font-family: "Poppins", sans-serif;
  }

  .submit-button {
    border: none;
    background: none;
    cursor: pointer;
    border-left: 2px #a75d5d solid;
    transition: all 0.2s ease;
  }

  .submit-button:hover {
    transform: scale(1.09);
  }

  .search-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;

export default Wrapper;
