import React, { useState, useEffect } from "react";
import Header from "./Header";
import { API } from "./Api";

import FilterComponent from "./components/FilterComponent";
import LoadingIndicator from "./components/LoadingIndicator";
import Average from "./components/Average";
import Download from "./components/Download";
import Wrapper from "./assets/wrappers/EventScore";

const AdminEventScore = () => {
  const [scores, setScores] = useState([]);
  const [page, setPage] = useState(1);
  // Using additional states to store all scores and the current filter option
  const [selectedOption, setSelectedOption] = useState("All");
  const [allScores, setAllScores] = useState([]);
  // State for tracking "Load More" button
  const [isLoading, setIsLoading] = useState(false);
  // States for storing averages
  const [avgPointsEarned, setAvgPointsEarned] = useState(0);
  const [avgPointsYTD, setAvgPointsYTD] = useState(0);
  const [avgRunSec, setAvgRunSec] = useState(0);
  const [avgRunMPH, setAvgRunMPH] = useState(0);
  const [avgRunMPHLastYear, setAvgRunMPHLastYear] = useState(0);
  // State to store the current filtered data
  const [selectedOptionData, setSelectedOptionData] = useState([]);
  console.log(selectedOptionData);

  const [tokenInLocalStorage, setTokenInLocalStorage] = useState("");
  console.log(tokenInLocalStorage);

  const currentYear = new Date().getUTCFullYear();
  const lastYear = currentYear - 1;

  // useEffect(() => {
  //   console.log("first hook executes");
  //   fetchScores();
  // }, [page]);

  // Get the token from localstorage and include it when sending POST request
  useEffect(() => {
    const token = localStorage.getItem("token");
    setTokenInLocalStorage(token);
    console.log("second hook executes");
    fetchScores();
  }, []);

  const fetchScores = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/admin_evententries`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({ page: page }),
      });

      if (response.status === 200) {
        const data = await response.json();
        console.log(data);
        setScores(data);
        setAllScores(data);
        calculateAverages(data);
        setIsLoading(false);
      } else {
        console.log("Failed to fetch event scores");
      }
    } catch (error) {
      console.error("Error fetching event scores:", error);
    }
  };

  const handleSignOut = () => {
    console.log("signout");
  };

  const handleLoadMore = async () => {
    setIsLoading(true);
    try {
      const nextPage = page + 1;
      const response = await fetch(`${API}/admin_evententries`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({ page: nextPage }),
      });
      const data = await response.json();
      if (response.status === 200) {
        const updatedScores = [...allScores, ...data];
        setAllScores(updatedScores);
        setPage(nextPage); // Update the page value
        if (selectedOption === "All") {
          console.log("The option is all");
          setScores(updatedScores);
          calculateAverages(updatedScores);
          setIsLoading(false);
        } else {
          const filteredData = updatedScores.filter(
            (item) => item.dog_name === selectedOption
          );
          setScores(filteredData);
          calculateAverages(filteredData);
          setIsLoading(false);
        }
        // console.log("This is selected option", selectedOption);
        // console.log("This is updatedScore", updatedScores);

        setIsLoading(false);
      } else {
        console.log("Failed to fetch event scores");
      }
    } catch (error) {
      console.error("Error fetching event scores:", error);
      setIsLoading(false);
    }
  };

  // Function To Handle Changing the Filter Option
  const handleFilterChange = (event) => {
    setSelectedOption(event.target.value);

    const selectedValue = event.target.value;

    if (selectedValue === "All") {
      setScores(allScores);
      calculateAverages(allScores);
    } else {
      const filteredData = allScores.filter(
        (item) => item.user_email === selectedValue
      );
      setScores(filteredData);
      setSelectedOptionData(filteredData);
      calculateAverages(filteredData);
    }
  };

  // Function To Calculate Averages
  const calculateAverages = (data) => {
    const totalCount = data.length;
    // console.log("Data When Calculating the Average", data);
    const sumRunInSecs = data.reduce((sum, obj) => sum + obj.run_in_secs, 0);
    const avgRunInSecs = (sumRunInSecs / totalCount).toFixed(2);

    const sumPointsYTD = data.reduce((sum, obj) => sum + obj.points_for_run, 0);
    const avgPointsYTD = (sumPointsYTD / totalCount).toFixed(2);

    setAvgPointsYTD(avgPointsYTD);
    setAvgRunSec(avgRunInSecs);

    ///// Calculating the Average MPH //////
    const groupedEvents = {};

    for (const event of data) {
      const { event_number, updated_time } = event;
      const year = new Date(updated_time).getFullYear();
      const groupKey = `${year}_${event_number}`;

      if (!groupedEvents[groupKey]) {
        groupedEvents[groupKey] = [];
      }

      groupedEvents[groupKey].push(event);
    }

    const yearlyResults = {};

    for (const groupKey in groupedEvents) {
      const year = groupKey.split("_")[0];
      const group = groupedEvents[groupKey];
      const maxMPH = Math.max(...group.map((event) => event.run_in_mph));

      if (!yearlyResults[year]) {
        yearlyResults[year] = {
          sumOfMaxValues: 0,
          numberOfGroups: 0,
        };
      }

      yearlyResults[year].sumOfMaxValues += maxMPH;
      yearlyResults[year].numberOfGroups++;
    }

    console.log("Yearly result", yearlyResults);

    const currentYear = new Date().getUTCFullYear();
    const lastYear = currentYear - 1;

    // Summation of Max of each event  AND number of events
    const sumOfMaxValues = yearlyResults[currentYear].sumOfMaxValues.toFixed(2);
    const totalUniqueEvents = yearlyResults[currentYear].numberOfGroups;
    // Average MPH = Summation of Max of each event  / number of events
    const averageRunMPH = sumOfMaxValues / totalUniqueEvents;

    setAvgRunMPH(averageRunMPH.toFixed(2));

    // Average MPH for Last Year

    if (yearlyResults[lastYear]) {
      const sumOfMaxValuesLastYear =
        yearlyResults[lastYear].sumOfMaxValues.toFixed(2);
      const totalUniqueEventsLastYear = yearlyResults[lastYear].numberOfGroups;
      // Average MPH = Summation of Max of each event  / number of events
      const averageRunMPHLastYear =
        sumOfMaxValuesLastYear / totalUniqueEventsLastYear;

      setAvgRunMPHLastYear(averageRunMPHLastYear.toFixed(2));
    }

    ///// Calculating the total points //////
    const yearlyPoints = {};

    for (const event of data) {
      const year = new Date(event.updated_time).getFullYear();
      const pointsForRun = event.points_for_run;

      if (!yearlyPoints[year]) {
        yearlyPoints[year] = 0;
      }

      yearlyPoints[year] += pointsForRun;
    }

    console.log(yearlyPoints[currentYear].toFixed(2));
    setAvgPointsEarned(yearlyPoints[currentYear].toFixed(2));
  };

  return (
    <Wrapper>
      <h1 style={styles.pageTitle}>FastCAT Performance Spreadsheet</h1>
      <div className="sub-container">
        <FilterComponent
          // options={scores.map((item) => item.dog_name)}
          options={[...new Set(allScores.map((item) => item.user_email))]}
          selectedOption={selectedOption}
          handleChange={handleFilterChange}
          labeltext="Filter By User Email:"
        />
        <div style={styles.averageContainer}>
          {/* {selectedOption !== "All" && (
            <Average label={"Total Points"} value={avgPointsEarned} />
          )}
          <Average label={"Average Points YTD"} value={avgPointsYTD} />
          <Average label={"Average Run(Sec)"} value={avgRunSec} />
          <Average label={`Avg Run ${currentYear} (MPH)`} value={avgRunMPH} />
          <Average
            label={`Avg Run ${lastYear} (MPH)`}
            value={avgRunMPHLastYear}
          /> */}
        </div>
        <Download table={scores} name="event" />
      </div>
      <table style={styles.table}>
        <thead>
          <tr className="table-row">
            <th style={styles.tableHeader}>ID</th>
            <th style={styles.tableHeader}>User</th>
            <th style={styles.tableHeader}>Email</th>
            <th style={styles.tableHeader}>Dog</th>
            <th style={styles.tableHeader}>Parent Event Number</th>
            <th style={styles.tableHeader}>Child Event Number</th>
            <th style={styles.tableHeader}>Event</th>
            <th style={styles.tableHeader}>Points Earned</th>
            <th style={styles.tableHeader}>Points YTD</th>
            <th style={styles.tableHeader}>Run (secs)</th>
            <th style={styles.tableHeader}>Run (MPH)</th>
            <th style={styles.tableHeader}>Title Achieved</th>
            <th style={styles.tableHeader}>Comments</th>
            <th style={styles.tableHeader}>Updated at</th>
            {/* <th style={styles.tableHeader}>Rank</th> */}

            {/* Add more headers as needed */}
          </tr>
        </thead>
        <tbody>
          {scores.map((score, index) => (
            <tr
              key={score.id}
              style={
                index % 2 === 0
                  ? { backgroundColor: "#e3e3e3" }
                  : { backgroundColor: "#ffffff" }
              }
            >
              <td style={styles.tableCell} className="table-cell">
                {score.id}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.user}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.user_email}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.dog_name}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.event_number}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.child_event}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.hostclub} {score.event_start_date} -
                {score.event_close_date}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.points_for_run}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.points_ytd}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.run_in_secs}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.run_in_mph}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.title_achieved}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.comments}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.updated_time}
              </td>
              {/* <td style={styles.tableCell} className="table-cell">
                --
              </td> */}
              {/* Add more columns as needed */}
            </tr>
          ))}
        </tbody>
      </table>
      {/* <button style={styles.loadMoreButton} onClick={handleLoadMore}>
        {isLoading ? <LoadingIndicator /> : "Load More"}
      </button> */}
    </Wrapper>
  );
};

const styles = {
  pageTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "20px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    border: "1px solid #ccc", // Add border to the table
    marginTop: "10px", // Add some space at the top
    // fontFamily: "Arial, sans-serif", // Improve font
  },
  tableHeader: {
    // background: "#f2f2f2",
    fontWeight: "bold",
    textAlign: "left",
  },
  tableCell: {
    // border: "1px solid #ccc", // Add border to table cells
    padding: "8px",
    textAlign: "left",
    border: "1px solid black",
  },

  averageContainer: {
    position: "relative",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
  },
  loadMoreButton: {
    padding: "10px 20px",
    background: "#4CAF50",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    margin: "10px auto",
    display: "block",
  },
};

export default AdminEventScore;
