import { styled } from "styled-components";

const Wrapper = styled.section`
  font-family: "Poppins", sans-serif;
  /* margin-left: 9rem; */
  .download {
    background: #4caf50;
    color: #fffbfb;
    padding: 5px 8px;
    margin: 0;
    border-radius: 8px;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in;
    img {
      width: 20px;
      height: 20px;
      padding: 0;
      margin: 0;
    }
    h3 {
      padding: 0;
      margin: 0;
      font-size: 18px;
      font-weight: 500;
      transition: all 0.2s ease-in;
    }
  }

  .download:hover {
    background: #3ca540;
    transform: scale(1.009);
  }
`;

export default Wrapper;
