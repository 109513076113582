import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  // useNavigate,
} from "react-router-dom"; // Replace with appropriate routing library
import Login from "./Login";
import Ownersignup from "./Signup";
import Blogs from "./Blogs";
import EventScore from "./EventScore";
import AdminDashboard from "./AdminDashboard";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );

  console.log(isLoggedIn);

  // #####  Token   ##### //

  // Creating a state to store if the token is present
  const [isTokenPresent, setIsTokenPresent] = useState(null);
  // Check if the token is present once when the component mounts.
  const tokenInLocalStorage = localStorage.getItem("token");
  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsTokenPresent(token);
  }, []);

  console.log(isTokenPresent);

  // #####             ##### //

  // const navigate = useNavigate();

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const checkLoginStatus = async () => {
    try {
      const storedEmail = localStorage.getItem("email");
      const storedPassword = localStorage.getItem("password");
      if (storedEmail && storedPassword) {
        setIsLoggedIn(true);
      }
    } catch (error) {
      console.error("Error retrieving stored credentials:", error);
    }
  };
  const handleSuccessfulSignup = async () => {
    alert("signup clicked");
  };

  const handleBlogs = async () => {
    // navigate("/blogs");
    console.log("Dfdf");
  };

  // const handleSuccessfulLogin = async (data) => {
  //   try {
  //     console.log("handlesuccess in app.js");
  //     setIsLoggedIn(true);
  //     localStorage.setItem("email", data.email);
  //     localStorage.setItem("password", data.password);
  //   } catch (error) {
  //     console.error("Error saving data:", error);
  //   }
  // };

  return (
    <Router>
      {/* <Header onSignOut={handleSignOut} /> Common header */}
      <Routes>
        {/* <Route path="/start" element={<Start />} /> */}
        <Route
          path="/"
          element={
            <Login
              setIsLoggedIn={setIsLoggedIn}
              setIsTokenPresent={setIsTokenPresent}
            />
          }
        />
        {/* <Route
          path="/signup"
          element={<Ownersignup onSuccessfulLogin={handleSuccessfulSignup} />}
        /> */}
        <Route
          path="/blogs"
          element={
            tokenInLocalStorage ? ( // check if the token is present, if not navigate the user to "/", login page.
              <Blogs onSuccessfulLogin={handleBlogs} />
            ) : (
              <Navigate to="/" />
            )
          }
        />
        <Route
          path="/eventscores"
          element={tokenInLocalStorage ? <EventScore /> : <Navigate to="/" />}
        />
        <Route
          path="/adminops"
          element={
            tokenInLocalStorage ? <AdminDashboard /> : <Navigate to="/" />
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
