import { styled } from "styled-components";

const Wrapper = styled.section`
  padding: 2px 10px;
  padding-left: 17px;
  margin-bottom: 20px;
  .label {
    margin-right: 6px;
    font-size: 18px;
    font-weight: 500;
  }

  .select {
    padding: 2px 4px;
    min-width: 8rem;
    border: 2px solid #000;
    border-radius: 5px;
  }

  .option {
    font-size: 17px;
  }
`;

export default Wrapper;
