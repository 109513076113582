import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const isAdmin = localStorage.getItem("isadmin") === "true";

  const handleSignOut = () => {
    // delete all localstorages and navigate to login page.
    navigate("/");
    localStorage.removeItem("token");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("email");
    localStorage.removeItem("password");
    console.log("Sign out");
  };

  return (
    <div style={styles.header}>
      <div style={styles.titleContainer}>
        <Link to="/eventscores" style={styles.headerButton}>
          EventScores
        </Link>
        <Link to="/blogs" style={styles.headerButton}>
          Forums
        </Link>
        {isAdmin && (
          <Link to="/adminops" style={styles.headerButton}>
            Admin Ops
          </Link>
        )}
      </div>
      <button style={styles.signOutButton} onClick={handleSignOut}>
        Sign Out
      </button>
    </div>
  );
};

const styles = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    background: "#f0f0f0",
    borderBottom: "1px solid #ccc",
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
  },
  ok: {
    marginTop: "30px",
    padding: "10px 20px",
    background: "#4CAF50",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    margin: "0 auto",
    display: "block",
  },
  headerButton: {
    marginRight: "20px",
    padding: "8px 16px",
    textDecoration: "none",
    color: "#ffff",
    background: "#4CAF50",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
  },
  signOutButton: {
    padding: "8px 16px",
    background: "#f44336",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
  },
};

export default Header;
