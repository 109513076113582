import Wrapper from "../assets/wrappers/FilterComponentWrapper";

const FilterComponent = ({
  options,
  selectedOption,
  handleChange,
  labeltext,
}) => {
  return (
    <Wrapper>
      <div>
        <label htmlFor="nameFilter" className="label">
          {labeltext}
        </label>
        <select
          id="nameFilter"
          className="select"
          value={selectedOption}
          onChange={handleChange}
        >
          <option className="option" value="All">
            All
          </option>
          {options.map((option) => (
            <option className="option" value={option} key={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </Wrapper>
  );
};

export default FilterComponent;
