import React, { useState } from "react";
import AdminEventScore from "./AdminEventScore";
import AdminUsers from "./AdminUsers";

import Header from "./Header";

const AdminDashboard = () => {
  const [selectedComponent, setSelectedComponent] = useState("eventScore"); // Default selection

  const handleSignOut = () => {
    console.log("signout");
  };

  return (
    <div>
      <Header onSignOut={handleSignOut} />
      <div style={dropdownStyles.container}>
        <select
          style={dropdownStyles.select}
          value={selectedComponent}
          onChange={(e) => setSelectedComponent(e.target.value)}
        >
          <option value="eventScore">Event Scores</option>
          <option value="users">Users</option>
        </select>
      </div>

      {selectedComponent === "eventScore" && <AdminEventScore />}
      {selectedComponent === "users" && <AdminUsers />}
    </div>
  );
};

const dropdownStyles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "20px 0", // Adjusts space around the dropdown
  },
  select: {
    padding: "10px", // Adds padding inside the dropdown
    borderRadius: "5px", // Rounds the corners of the dropdown
    border: "1px solid #ccc", // Adds a border to the dropdown
    fontSize: "16px", // Adjusts the font size
  },
};

export default AdminDashboard;
