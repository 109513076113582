import React from "react";
import styled, { keyframes } from "styled-components";

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Spinner = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 4px solid #ccc;
  border-top-color: #333;
  animation: ${spinAnimation} 0.8s linear infinite;
`;

const LoadingIndicator = () => {
  return (
    <LoadingContainer>
      <Spinner />
    </LoadingContainer>
  );
};

export default LoadingIndicator;
