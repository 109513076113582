import React, { useState, useEffect } from "react";
import Header from "./Header";
import { API } from "./Api";

import FilterComponent from "./components/FilterComponent";
import LoadingIndicator from "./components/LoadingIndicator";
import Average from "./components/Average";
import Download from "./components/Download";
import Wrapper from "./assets/wrappers/EventScore";

const EventScore = () => {
  const [scores, setScores] = useState([]);
  const [page, setPage] = useState(1);
  // Using additional states to store all scores and the current filter option
  const [selectedOption, setSelectedOption] = useState("All");
  const [allScores, setAllScores] = useState([]);
  // State for tracking "Load More" button
  const [isLoading, setIsLoading] = useState(false);
  // States for storing averages
  const [avgPointsEarned, setAvgPointsEarned] = useState(0);
  const [avgPointsYTD, setAvgPointsYTD] = useState(0);
  const [avgRunSec, setAvgRunSec] = useState(0);
  const [avgRunMPH, setAvgRunMPH] = useState(0);
  const [avgRunMPHLastYear, setAvgRunMPHLastYear] = useState(0);
  // State to store the current filtered data
  const [selectedOptionData, setSelectedOptionData] = useState([]);
  console.log(selectedOptionData);

  const [tokenInLocalStorage, setTokenInLocalStorage] = useState("");
  console.log(tokenInLocalStorage);

  const currentYear = new Date().getUTCFullYear();
  const lastYear = currentYear - 1;

  // useEffect(() => {
  //   console.log("first hook executes");
  //   fetchScores();
  // }, [page]);

  // Get the token from localstorage and include it when sending POST request
  useEffect(() => {
    const token = localStorage.getItem("token");
    setTokenInLocalStorage(token);
    console.log("second hook executes");
    fetchScores();
  }, []);

  const fetchScores = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/evententries`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({ page: page }),
      });

      if (response.status === 200) {
        const data = await response.json();

        const updatedData = calculatePointsYTD(data);

        setScores(updatedData);

        setAllScores(updatedData);

        calculateAverages(updatedData);

        setIsLoading(false);
      } else {
        console.log("Failed to fetch event scores");
      }
    } catch (error) {
      console.error("Error fetching event scores:", error);
    }
  };

  const handleSignOut = () => {
    console.log("signout");
  };

  const handleLoadMore = async () => {
    setIsLoading(true);
    try {
      const nextPage = page + 1;
      const response = await fetch(`${API}/evententries`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify({ page: nextPage }),
      });
      const data = await response.json();
      if (response.status === 200) {
        const updatedScores = [...allScores, ...data];
        const updatedData = calculatePointsYTD(updatedScores);
        setAllScores(updatedData);
        setPage(nextPage); // Update the page value
        if (selectedOption === "All") {
          console.log("The option is all");
          setScores(updatedData);
          calculateAverages(updatedData);
        } else {
          const filteredData = updatedData.filter(
            (item) => item.dog_name === selectedOption
          );
          setScores(filteredData);
          calculateAverages(filteredData);
        }
        // console.log("This is selected option", selectedOption);
        // console.log("This is updatedScore", updatedScores);

        setIsLoading(false);
      } else {
        console.log("Failed to fetch event scores");
      }
    } catch (error) {
      console.error("Error fetching event scores:", error);
    }
  };
  // show a dialog box
  const confirmDelete = async (id) => {
    console.log("Selected id", id);
    if (window.confirm("Are you sure you want to delete this score?")) {
      // send a post request with payload id
      // replace `YOUR_API_ENDPOINT` with the actual API endpoint

      console.log("Selected id 2", id);
      const response = await fetch(`${API}/evententry/${id}`, {
        method: "DELETE",
        body: JSON.stringify({ id }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.status === 200) {
        const data = await response.json();
        console.log("Data", data);
        console.log(scores);
        const updatedScores = scores.filter((score) => score.id !== id);
        const updatedData = calculatePointsYTD(updatedScores);
        setScores(updatedData);

        setAllScores(updatedData);

        calculateAverages(updatedData);

        // setAllScores(data);

        // calculateAverages(data);

        // setIsLoading(false);
      } else {
        console.log("Failed to fetch event scores");
      }
    }
  };
  // Function To Handle Changing the Filter Option
  const handleFilterChange = (event) => {
    setSelectedOption(event.target.value);

    const selectedValue = event.target.value;

    if (selectedValue === "All") {
      setScores(allScores);
      calculateAverages(allScores);
    } else {
      const filteredData = allScores.filter(
        (item) => item.dog_name === selectedValue
      );

      setScores(filteredData);

      setSelectedOptionData(filteredData);

      calculateAverages(filteredData);
    }
  };

  function getTitleAchieved(pointsForRun) {
    let output;
    if (pointsForRun >= 0 && pointsForRun <= 150) {
      output = "No Title Achieved";
    } else if (pointsForRun >= 150 && pointsForRun < 500) {
      output = "BCAT";
    } else if (pointsForRun >= 500 && pointsForRun < 1000) {
      output = "DCAT";
    } else if (pointsForRun >= 1000 && pointsForRun < 1500) {
      output = "FCAT";
    } else if (pointsForRun >= 1500) {
      const value = 500;

      const fcatValue = Math.floor(pointsForRun / value) - 1;
      output = "FCAT" + fcatValue;
    } else {
      output = "No Title Achieved";
    }

    return output;
  }

  const calculatePointsYTD = (data) => {
    // console.log("SORTED DATA", sortedData); // Debug log to verify sorted data
    const sortedData = data.sort(
      (a, b) => new Date(a.updated_time) - new Date(b.updated_time)
    );

    const pointsByDog = {};

    sortedData.forEach((entry) => {
      if (!pointsByDog[entry.dog_name]) {
        pointsByDog[entry.dog_name] = 0;
      }
      pointsByDog[entry.dog_name] += entry.points_for_run;
      entry.points_ytd = pointsByDog[entry.dog_name].toFixed(2);
      entry.title_achieved = getTitleAchieved(entry.points_ytd);
    });

    const descSortedData = sortedData.sort(
      (a, b) => new Date(b.updated_time) - new Date(a.updated_time)
    );
    return descSortedData;
  };

  // Function To Calculate Averages
  const calculateAverages = (full_data) => {
    const data = full_data.filter((item) => item.event_number !== 9999999999);
    const totalCount = data.length;
    // console.log("Data When Calculating the Average", data);
    const sumRunInSecs = data.reduce((sum, obj) => sum + obj.run_in_secs, 0);
    const avgRunInSecs = (sumRunInSecs / totalCount).toFixed(2);
    const sumPointsYTD = data.reduce((sum, obj) => sum + obj.points_for_run, 0);
    const avgPointsYTD = (sumPointsYTD / totalCount).toFixed(2);
    setAvgPointsYTD(avgPointsYTD);
    setAvgRunSec(avgRunInSecs);
    ///// Calculating the Average MPH //////
    const groupedEvents = {};

    for (const event of data) {
      const { event_number, updated_time } = event;
      const year = new Date(updated_time).getFullYear();
      const groupKey = `${year}_${event_number}`;
      if (!groupedEvents[groupKey]) {
        groupedEvents[groupKey] = [];
      }

      groupedEvents[groupKey].push(event);
    }
    const yearlyResults = {};
    const currentYear = new Date().getUTCFullYear();
    const lastYear = currentYear - 1;

    for (const year of [currentYear, lastYear]) {
      if (!yearlyResults[year]) {
        yearlyResults[year] = {
          sumOfMaxValues: 0.0,
          numberOfGroups: 0.0,
        };
      }
    }

    for (const groupKey in groupedEvents) {
      const year = groupKey.split("_")[0];
      const group = groupedEvents[groupKey];
      const maxMPH = Math.max(...group.map((event) => event.run_in_mph));

      if (!yearlyResults[year]) {
        yearlyResults[year] = {
          sumOfMaxValues: 0,
          numberOfGroups: 0,
        };
      }

      yearlyResults[year].sumOfMaxValues += maxMPH;
      yearlyResults[year].numberOfGroups++;
    }

    console.log("Yearly result", yearlyResults);

    console.log("YEARLY RESULTS");
    console.log(yearlyResults);
    console.log("currentYear");
    console.log(currentYear);
    // Summation of Max of each event  AND number of events

    const sumOfMaxValues = yearlyResults[currentYear].sumOfMaxValues.toFixed(2);
    const totalUniqueEvents = yearlyResults[currentYear].numberOfGroups;
    // Average MPH = Summation of Max of each event  / number of events
    const average = sumOfMaxValues / totalUniqueEvents;
    const averageRunMPH = isNaN(average) ? 0 : average;

    setAvgRunMPH(averageRunMPH.toFixed(2));

    // Average MPH for Last Year

    if (yearlyResults[lastYear]) {
      const sumOfMaxValuesLastYear =
        yearlyResults[lastYear].sumOfMaxValues.toFixed(2);
      const totalUniqueEventsLastYear = yearlyResults[lastYear].numberOfGroups;
      // Average MPH = Summation of Max of each event  / number of events
      const averageRunMPHLastYear =
        sumOfMaxValuesLastYear / totalUniqueEventsLastYear;

      setAvgRunMPHLastYear(averageRunMPHLastYear.toFixed(2));
    }

    ///// Calculating the total points //////
    const yearlyPoints = {};

    const pointCurrentYear = new Date().getUTCFullYear();
    const pointslastYear = pointCurrentYear - 1;

    for (const year of [pointCurrentYear, pointslastYear]) {
      if (!yearlyPoints[year]) {
        yearlyPoints[year] = 0.0;
      }
    }

    for (const event of data) {
      const year = new Date(event.updated_time).getFullYear();
      const pointsForRun = event.points_for_run;

      if (!yearlyPoints[year]) {
        yearlyPoints[year] = 0;
      }

      yearlyPoints[year] += pointsForRun;
    }

    setAvgPointsEarned(yearlyPoints[currentYear].toFixed(2));
  };

  return (
    <Wrapper>
      <Header onSignOut={handleSignOut} />
      <h1 style={styles.pageTitle}>FastCAT Performance Spreadsheet</h1>
      <div className="sub-container">
        <FilterComponent
          // options={scores.map((item) => item.dog_name)}
          options={[...new Set(allScores.map((item) => item.dog_name))]}
          selectedOption={selectedOption}
          handleChange={handleFilterChange}
          labeltext="Filter By Dog Name:"
        />
        <div style={styles.averageContainer}>
          {selectedOption !== "All" && (
            <Average label={"Total Points"} value={avgPointsEarned} />
          )}
          <Average label={"Average Points YTD"} value={avgPointsYTD} />
          <Average label={"Average Run(Sec)"} value={avgRunSec} />
          <Average label={`Avg Run ${currentYear} (MPH)`} value={avgRunMPH} />
          <Average
            label={`Avg Run ${lastYear} (MPH)`}
            value={avgRunMPHLastYear}
          />
        </div>
        <Download
          table={scores}
          name="event"
          avgpoints={avgPointsYTD}
          avgrunsec={avgRunSec}
          avgrunmph={avgRunMPH}
          avgrunmphlastyear={avgRunMPHLastYear}
        />
      </div>
      <table style={styles.table}>
        <thead>
          <tr className="table-row">
            {/* <th style={styles.tableHeader}></th> */}
            <th style={styles.tableHeader}>Dog</th>
            <th style={styles.tableHeader}>Event Number</th>
            <th style={styles.tableHeader}>Event</th>
            <th style={styles.tableHeader}>Points Earned</th>
            <th style={styles.tableHeader}>Points YTD</th>
            <th style={styles.tableHeader}>Run (secs)</th>
            <th style={styles.tableHeader}>Run (MPH)</th>
            <th style={styles.tableHeader}>Title Achieved</th>
            <th style={styles.tableHeader}>Comments</th>
            <th style={styles.tableHeader}>Updated at</th>
            {/* <th style={styles.tableHeader}>Rank</th> */}
          </tr>
        </thead>
        <tbody>
          {scores.map((score, index) => (
            <tr
              key={score.id}
              style={
                index % 2 === 0
                  ? { backgroundColor: "#e3e3e3" }
                  : { backgroundColor: "#ffffff" }
              }
            >
              {/* <td style={styles.tableCell}>
                <input type="checkbox" />
              </td> */}
              <td style={styles.tableCell} className="table-cell">
                {score.dog_name}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.child_event}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.hostclub} {score.event_start_date} -
                {score.event_close_date}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.points_for_run}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.points_ytd}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.run_in_secs}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.run_in_mph}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.title_achieved}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.comments}
              </td>
              <td style={styles.tableCell} className="table-cell">
                {score.updated_time}
              </td>
              {score.event_number !== 9999999999 && (
                <td style={styles.tableCell}>
                <button
                  style={styles.deletebutton}
                  onClick={() => confirmDelete(score.id)}
                  >
                    Delete
                  </button>
                </td>
                  )}
            </tr>
          ))}
        </tbody>
      </table>
      <button style={styles.loadMoreButton} onClick={handleLoadMore}>
        {isLoading ? <LoadingIndicator /> : "Load More"}
      </button>
    </Wrapper>
  );
};

const styles = {
  pageTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "20px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    border: "1px solid #ccc", // Add border to the table
    marginTop: "10px", // Add some space at the top
    // fontFamily: "Arial, sans-serif", // Improve font
  },
  tableHeader: {
    // background: "#f2f2f2",
    fontWeight: "bold",
    textAlign: "left",
  },
  tableCell: {
    // border: "1px solid #ccc", // Add border to table cells
    padding: "8px",
    textAlign: "left",
    border: "1px solid #ccc",
  },

  averageContainer: {
    position: "relative",
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
  },
  loadMoreButton: {
    padding: "10px 20px",
    background: "#4CAF50",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    margin: "10px auto",
    display: "block",
  },
  deletebutton: {
    padding: "8px 16px",
    background: "#f44336",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
  },
};

export default EventScore;
