import XLSX from "xlsx";

import React from "react";
import Wrapper from "../assets/wrappers/Download";
import downloadIcon from "../assets/svgs/download-solid.svg";

const Download = ({
  table,
  name,
  avgpoints = 0,
  avgrunsec = 0,
  avgrunmph = 0,
  avgrunmphlastyear = 0,
}) => {
  let modifiedTable;
  let filename;
  if (name == "event") {
    filename = "DSR Spreadsheet.xlsx";
    modifiedTable = table.map((obj) => ({
      id: obj.id,
      user: obj.user,
      user_email: obj.user_email,
      dog: obj.dog_name,
      event_number: obj.child_event,
      Event: obj.hostclub + " " + obj.event_start_date + " - " + obj.event_close_date,
      points_earned: obj.points_for_run,
      points_ytd: obj.points_ytd,
      "Run(sec)": obj.run_in_secs,
      "Run(mph)": obj.run_in_mph,
      "Title Achieved": obj.title_achieved,
      Comments: obj.comments,
      "Updated Time": obj.updated_time,
    }));
    if (avgpoints != 0) {
      modifiedTable.push({});
      modifiedTable.push({
        id: "Average Points YTD",
        user: avgpoints,
      });
      modifiedTable.push({
        id: "Average Run(Sec)",
        user: avgrunsec,
      });
      modifiedTable.push({
        id: "Avg Run (Current Year)",
        user: avgrunmph,
      });
      modifiedTable.push({
        id: "Avg Run (Last Year)",
        user: avgrunmphlastyear,
      });
    }
  } else {
    filename = "Users.xlsx";
    modifiedTable = table.map((obj) => ({
      id: obj.id,
      user: obj.name,
      phone: obj.phone,
      email: obj.email,
      address: obj.address,
      created_time: obj.created_time,
    })); // Added closing curly brace here
  }

  const handleDownloadTable = () => {
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(modifiedTable);

    XLSX.utils.book_append_sheet(wb, ws, "table");

    XLSX.writeFile(wb, filename);
  };

  return (
    <Wrapper>
      <div className="download" onClick={handleDownloadTable}>
        <img src={downloadIcon} alt="download icon" />
        <h3>Download</h3>
      </div>
    </Wrapper>
  );
};

export default Download;
