import Wrapper from "../assets/wrappers/Average";

const Average = ({ label, value }) => {
  return (
    <Wrapper>
      <div className="avg-container">
        <h3>{label}</h3>
        <p>{value}</p>
      </div>
    </Wrapper>
  );
};

export default Average;
