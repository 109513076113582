import { styled } from "styled-components";

const Wrapper = styled.section`
  font-family: "Poppins", sans-serif;

  .sub-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding-right: 2rem;
  }
  .table-row {
    th {
      padding: 8px 0;
    }
    th:nth-child(2) {
      padding-left: 10px;
    }
    th:nth-child(3) {
      padding-left: 10px;
    }
    th:nth-child(1) {
      padding-left: 10px;
    }
  }
`;

export default Wrapper;
