import React, { useState, useEffect } from "react";
import Header from "./Header";

import LoadingIndicator from "./components/LoadingIndicator";
import Search from "./components/Search";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [page, setPage] = useState(1); // For pagination
  const [tokenInLocalStorage, setTokenInLocalStorage] = useState(""); // For Token
  const [isLoading, setIsLoading] = useState(false);

  console.log(tokenInLocalStorage);
  useEffect(() => {
    fetchBlogs();
  }, [page]); // Fetch blogs when page changes

  // Get the token from localstorage and include it when sending POST request
  useEffect(() => {
    const token = localStorage.getItem("token");
    setTokenInLocalStorage(token);
  }, []);

  const fetchBlogs = async () => {
    setIsLoading(true);
    try {
      // Simulated data for testing
      setBlogs([
        {
          id: 1,
          user: "pechi",
          comment: "Great run, eventually all the best",
          datetime: "2023-08-10 10:45",
        },
        {
          id: 2,
          user: "pechi",
          comment: "Great run, eventually all the best",
          datetime: "2023-08-10 10:45",
        },
        {
          id: 3,
          user: "pechi",
          comment: "Great run, eventually all the best",
          datetime: "2023-08-10 10:45",
        },
        {
          id: 4,
          user: "Denise",
          comment: "Great run, eventually all the best",
          datetime: "2023-08-10 10:45",
        },
        {
          id: 5,
          user: "John",
          comment: "Great run, eventually all the best",
          datetime: "2023-08-10 10:45",
        },
        {
          id: 6,
          user: "sd",
          comment: "Great run, eventually all the best",
          datetime: "2023-08-10 10:45",
        },
      ]);

      // Replace this part with your actual fetch logic
      // const response = await fetch(`${API}/blogs`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({ page, token: tokenInLocalStorage }), // Include page for pagination
      // });
      // const data = await response.json();
      // if (data.success) {
      //   setBlogs((prevBlogs) => [...prevBlogs, ...data.posts]);
      // } else {
      //   console.log("Failed to fetch blogs");
      // }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  const handleLoadMore = () => {
    setIsLoading(true);
    setPage((prevPage) => prevPage + 1); // Load next set of blogs
    setIsLoading(false);
  };

  return (
    <div>
      <Header /> {/* Include header */}
      <h1 style={styles.blogTitle}>FastCAT Forum coming soon.</h1>
      {/* <div style={styles.cardContainer}>
        <Search />
        {blogs.map((blog) => (
          <div key={blog.id} style={styles.card}>
            <h3 style={styles.userName}>{blog.user}</h3>
            <p style={styles.comment}>{blog.comment}</p>
            <p style={styles.datetime}>{blog.datetime}</p>
          </div>
        ))}
      </div>
      <button style={styles.loadMoreButton} onClick={handleLoadMore}>
        {isLoading ? <LoadingIndicator /> : "Load More"}
      </button> */}
    </div>
  );
};

const styles = {
  cardContainer: {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  card: {
    width: "30%",
    border: "1px solid #ccc",
    padding: "10px",
    marginBottom: "20px",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
  },
  userName: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "green",
    marginBottom: "8px",
  },
  comment: {
    fontSize: "16px",
    marginBottom: "8px",
  },
  datetime: {
    fontSize: "14px",
    textAlign: "right",
    color: "#10291d",
  },
  loadMoreButton: {
    padding: "10px 20px",
    background: "#4CAF50",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    borderRadius: "8px",
    margin: "0 auto",
    display: "block",
  },
  blogTitle: {
    fontSize: "50px",
    fontWeight: "bold",
    color: "green",
    textAlign: "center",
    marginBottom: "20px",
  },
};

export default Blogs;
