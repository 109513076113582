import { styled } from "styled-components";

const Wrapper = styled.section`
  .avg-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border: 0.5px #d4bdbd solid;
    /* border-radius: 5px; */
    /* padding: 3px; */
    box-shadow: 2px 3px 3px #e3e3e3;
    font-family: "Poppins", sans-serif;
    h3 {
      font-size: 15px;
      padding: 0;
      margin: 0;
      background: #c4cbe4;
      padding: 3px;
    }

    p {
      padding: 3px;
      margin: 0;
    }
  }
`;

export default Wrapper;
