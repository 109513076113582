import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "./Api";

import LogoImg from "./assets/images/logo.png";
import HomeImg from "./assets/images/home.jpg";
import HomeImg1 from "./assets/images/home1.jpg";
import HomeImg2 from "./assets/images/home2.jpg";
import HomeImg3 from "./assets/images/home3.jpg";

const Login = ({ setIsLoggedIn, setIsTokenPresent }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault(); // Preventing the default page refresh behavior of the form element when enter key is pressed.
    setIsSubmitting(true);
    const url = API + "/login";
    if (!isValidEmail(email)) {
      alert("Invalid email address");
    } else if (password.length <= 1) {
      alert("Please enter a correct password");
    } else if (password.length === 0) {
      alert("Please enter a password");
    } else if (email.length === 0) {
      alert("Please enter an email");
    } else {
      console.log(url, email, password);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Add other headers if needed
        },
        body: JSON.stringify({ email: email, password: password }),
      });

      // ##### Parsing the response from API and extracting the token from it.   ##### //
      const parsedResponse = await response.json(); // Parse the incoming response
      const responseToken = parsedResponse.access_token; // Extract the token from the parsed response
      // console.log("This is the parsed response", parsedResponse);
      // console.log("This is the token from response", responseToken);

      // #####             ##### //

      console.log(response, ">>>>>>>");
      console.log("STATUS");
      console.log(response.status);
      if (response.status === 200) {
        setIsLoggedIn(true);
        // const data = response.json(); // The response parsed above
        const data = parsedResponse;

        // ##### Storing the incoming token in localStorage and setting the TokenPresent State true.  ##### //
        localStorage.setItem("token", responseToken);
        setIsTokenPresent(true);
        // #####             ##### //
        localStorage.setItem("isadmin", data.user.isadmin.toString());
        handleSuccessfulLogin({ data });
      } else {
        setEmail("");
        setPassword("");
        alert("Invalid email or password");
      }
    }
    setIsSubmitting(false);
  };

  const handleSuccessfulLogin = async (data) => {
    try {
      // Set the user as logged in
      console.log("handlesuccess in side login.js");
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("email", data.access_token);
      localStorage.setItem("password", data.password);

      // Redirect to the /blogs page
      navigate("/eventscores");
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSignup = () => {
    console.log("ghgh"); // Call the provided signup function from parent component
    navigate("/signup");
  };

  return (
    <div style={styles.container}>
      <div style={styles.centerContent}>
        <img src={LogoImg} alt="Dog Sports" style={styles.imageStyle} />
        <h1 style={styles.welcomeText}>Welcome to DogSportsRecords</h1>
        {/* Adding <form> element  to handle `Enter` key*/}
        <form onSubmit={handleLogin}>
          <input
            style={styles.input}
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            style={styles.input}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
          <p> Use APP Credentials to login.</p>
          <a href="https://pechi.notion.site/PRIVACY-POLICY-GLOBAL-7742a41686c14ae895ab365d59bbb0d5?pvs=4">
            Privacy Policy
          </a>
          <br></br>
          <br></br>
          <div style={styles.buttonContainer}>
            <button style={styles.button} onClick={handleLogin}>
              {isSubmitting ? "Logging in..." : "Login"}
            </button>
            {/* <button style={styles.signupbutton} onClick={handleSignup}>
              New User? Signup
            </button> */}
          </div>
        </form>
        <div style={styles.imageRow}>
          <img src={HomeImg} alt="Image 1" style={styles.imageStyle} />
          <img src={HomeImg1} alt="Image 1" style={styles.imageStyle} />
          <img src={HomeImg2} alt="Image 2" style={styles.imageStyle} />
          <img src={HomeImg3} alt="Image 3" style={styles.imageStyle} />
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#FFFFFF",
  },
  centerContent: {
    textAlign: "center",
  },
  welcomeText: {
    fontSize: 30,
    fontWeight: "bold",
    marginBottom: 100,
  },
  input: {
    width: "100%",
    height: 40,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    borderRadius: 8,
    padding: 8,
    marginBottom: 10,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    backgroundColor: "#4CAF50",
    padding: 12,
    borderRadius: 8,
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: "bold",
    border: "none",
    cursor: "pointer",
    marginBottom: 10,
  },
  signupbutton: {
    backgroundColor: "#000000",
    padding: 12,
    borderRadius: 8,
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: "bold",
    border: "none",
    cursor: "pointer",
    marginBottom: 10,
  },
  imageStyle: {
    width: "50%", // or any specific width
    height: "auto", // maintain aspect ratio
    marginBottom: 0,
    width: "20%",
    height: "30",
    marginBottom: 0, // space between image and text
  },
  bottomimageStyle: {
    width: "20%", // or any specific width
    height: "30", // maintain aspect ratio
    marginBottom: 0, // space between image and text
  },
  imageRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#FFFFFF",
  },
  centerContent: {
    width: "100%", // Ensure this div doesn't exceed the width of the screen
    maxWidth: "500px", // Max width of the login form area
    textAlign: "center",
    padding: "20px", // Add padding
  },
  welcomeText: {
    fontSize: "2rem", // Use responsive font size units
    fontWeight: "bold",
    marginBottom: "1.5rem",
  },
  input: {
    width: "100%", // Use 100% of the parent width
    height: "2.5rem",
    borderWidth: 1,
    borderColor: "#CCCCCC",
    borderRadius: "0.5rem",
    padding: "0.5rem",
    marginBottom: "1rem",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    backgroundColor: "#4CAF50",
    padding: "0.75rem",
    borderRadius: "0.5rem",
    color: "#FFFFFF",
    fontSize: "1rem",
    fontWeight: "bold",
    border: "none",
    cursor: "pointer",
    marginBottom: "1rem",
    width: "100%", // Make the button full width
  },
  imageStyle: {
    width: "50%", // Make images responsive
    height: "20%", // Maintain aspect ratio
    marginBottom: "1rem", // Space between image and text
  },
  imageRow: {
    display: "flex",
    flexDirection: "column", // Ensure images are in a row
    flexWrap: "wrap", // Allow images to wrap on smaller screens
    justifyContent: "center", // Center the images horizontally
    alignItems: "center",
    // overflow: "hidden", // Hide any overflowing images
    maxHeight: "200px", // Limit the height of the image row
    gap: "1rem", // Add space between images
    marginTop: "1rem", // Space above the image row
  },
};

export default Login;
